import React, { useEffect, useState } from 'react';
import PaymentQRCode from './PaymentQRCode';
import './App.css';
import { QRCodeCanvas } from 'qrcode.react'; // Import QRCodeCanvas
function App() {
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [amount, setAmount] = useState('');

  // Function to fetch the QR code URL with the given transactionId and amount
  const fetchQRCodeUrl = async (transactionId, amount) => {
    try {
      const apiUrl = `http://103.20.214.27:3000/transaction?transaction_id=${transactionId}&amount=${amount}`; // Construct the URL with query parameters
      const response = await fetch(apiUrl);
      const data = await response.json();
      console.log(data);
      const responseData = JSON.parse(data.data); // Parse the cleaned data
        console.log(responseData.message); // Log the parsed response
      console.log(responseData);
      console.log(responseData.refId);
      if (responseData && responseData.refId) {
        // Assuming the other parameters are constant
        const otherParams = 'upi://pay?pa=docmaster@icici&pn=Abc'; // Replace this with your actual other parameters if needed
        const endParams = '&am=1&cu=INR&mc=5411';  // Replace this with your actual end parameters if needed
setQrCodeUrl(`${otherParams}&tr=${responseData.refId}&am=${amount}${endParams}`); // Correct string interpolation
console.log(qrCodeUrl);
      }
    } catch (error) {
     // console.error('Error fetching QR code URL:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (transactionId && amount) {
      // Fetch QR code URL with the transaction ID and amount
      fetchQRCodeUrl(transactionId, amount);
    } else {
      alert('Please enter both transaction ID and amount');
    }
  };

  return (
    <div className="App">
      <h1>QR Code Payment</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Transaction ID:
            <input
              type="text"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit">Generate QR Code</button>
      </form>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Scan to Pay</h2>
      <QRCodeCanvas value={qrCodeUrl} size={256} includeMargin={true} />
      <p>{qrCodeUrl}</p>
    </div>
    </div>
  );
}

export default App;
